import React from 'react';
import ReactDOM from 'react-dom';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import LocalEn from './locales/en.json';
import LizardsRockImage from './resources/images/lizard.jpg';
import styles from './index.scss';

const removeLoader = (delay) => {
  const loader = document.getElementById('loader');
  if (loader) {
    setTimeout(() => {
      loader.classList.add('fade-out');
      setTimeout(() => {
        if (loader.parentNode) {
          loader.parentNode.removeChild(loader);
        }
      }, 2000);
    }, delay || 0);
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    detection: {
      order: ['localStorage', 'navigator', 'querystring', 'cookie', 'htmlTag'],
    },
    resources: {
      en: { translation: LocalEn },
    },
  });

const theme = createMuiTheme({});

export default class App extends React.Component {
  componentDidMount() {
    removeLoader();
  }

  render() {
    const { children } = this.props;
    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          margin: 0,
          padding: 0,
        }}
      >
        <ThemeProvider theme={theme}>
          <img className={styles.image} alt="" src={LizardsRockImage} />
        </ThemeProvider>
      </div>
    );
  }
}
ReactDOM.render(<App />, document.getElementById('root'));
